export function VideosFilter() {

    var categories = document.querySelectorAll('.cat')
    var button = document.getElementById('loadmore_videos');
    var page = 0;
    var loading = document.getElementById('loading_animation');

    var container = document.getElementById('response')

    function loadItems() {
        var beforeSend = function () {
            container.innerHTML = ""
            button.style.display = 'none'
            loading.classList.toggle('d-none')
        }
        beforeSend()
        var xhr = new XMLHttpRequest();
        xhr.open('POST', steven_loadmore_videos.ajaxurl);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            page++;
            if (xhr.status === 200) {
                if (xhr.responseText) {
                    button.style.display = 'inline-flex'
                    loading.classList.toggle('d-none')
                    container.innerHTML = xhr.response;
                    var span = container.querySelector('#max_item')
                    if (span) {
                        var max_item = parseInt(span.innerText)
                    }
                    if (!max_item || page >= max_item) {
                        button.style.display = 'none'
                    } else {
                        steven_loadmore_videos.max_page = max_item
                    }

                }
            }
        };
        xhr.send("action=loadmore_videos&query=" + encodeURI(steven_loadmore_videos.posts) + "&page=" + page + "&cat=" + steven_loadmore_videos.cat)
        return false;
    }

    categories.forEach(item => {
        item.addEventListener('click', e => {
            let id = item.querySelector('strong').id;
            page = 0;
            steven_loadmore_videos.current_page = 1;
            steven_loadmore_videos.cat = id
            loadItems();
        })
    })


}

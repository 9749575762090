export function playvideo() {
    var videoslider2 = document.querySelectorAll(".section-gallery")
    if (videoslider2 !== 'undefined' && videoslider2 != null) {
        if (videoslider2.length >= 1) {
            var iframes = document.querySelectorAll("iframe");
            var thumbnails = document.querySelectorAll(".thumbnail")
            var play = document.querySelectorAll(".play-btn");

            for (let v = 0; v < iframes.length; v++) {
                if (play[v] !== undefined && play[v] !== null) {
                    play[v].addEventListener("click", (evt) => {
                        var thumb = thumbnails[v];
                        var video = iframes[v];
                        var btn = play[v];
                        video.src = btn.getAttribute("value");
                        btn.style.display = "none"
                        thumb.style.display = "none"
                    })
                }
            }
        }
    }

    var videoWrap = document.querySelectorAll(".video-wrap")
    if (videoWrap !== 'undefined' && videoWrap != null) {
        if (videoWrap.length >= 1) {
            var iframes = document.querySelectorAll("iframe");
            var thumbnails = document.querySelectorAll(".thumbnail")
            var play = document.querySelectorAll(".play-btn");
            var videoContainer = document.querySelectorAll(".has-video");
            for (let v = 0; v < iframes.length; v++) {
                if (play[v] !== undefined && play[v] !== null) {
                    play[v].addEventListener("click", (evt) => {
                        var thumb = thumbnails[v];
                        var video = iframes[v];
                        var btn = play[v];
                        video.src = btn.getAttribute("value");
                        btn.style.display = "none";
                        thumb.style.display = "none";
                        videoWrap[v].style.height = "auto";
                        videoContainer[v].style.top = "0";
                    })
                }
            }
        }
    }
}
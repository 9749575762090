export function newsletter() {
    var container = document.createElement("div");
    var dropdown = document.createElement("div");
    dropdown.classList.add('mailster-lists-dropdown')
    dropdown.textContent = 'NEWSLETTER(THEMENAUSWAHL)'
    var parent = document.querySelector('.mailster-lists-wrapper')
    if (parent != null && container !== null) {
        parent.append(container)
        container.append(dropdown)
        var list = document.querySelector('ul.mailster-list')
        if (list !== null) {
            container.append(list)
        }
        parent.style.display = 'block'
        parent.classList.remove('droptop')
        list.style.padding = 0;
        list.classList.add('no-height')
        list.style.overflow = 'hidden'
        var opened = false;
        if (dropdown !== null) {
            dropdown.addEventListener('click', e => {
                if (opened) {
                    parent.classList.remove('droptop')
                    list.classList.add('no-height')
                    list.style.overflow = 'hidden'
                } else {
                    list.classList.remove('no-height')
                    setTimeout(()=> {
                        list.style.overflow = 'auto'
                    },700)
                    parent.classList.add('droptop')
                    list.style.height = list.scrollHeight + "px";
                }
                opened = !opened;
            })
        }
    }


}

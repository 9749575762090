export function modalFormular() {
  const modalNewsletter = document.querySelector(".newsletter-modal");
  const sendBtn = document.querySelector(".sib-form-block__button");
  
  if (modalNewsletter) {
    const newsletterBtn = document.querySelector(".newsletter-btn");
    const openModalBtn = document.querySelector(".modal-open-btn");
    const closeModalBtn = modalNewsletter.querySelector(".modal-close-btn");
    const sibForm = modalNewsletter.querySelector("#sib-form-container");
    const body = document.querySelector("body");
    document.documentElement.style.setProperty(
      "--scroll-y",
      `${window.scrollY}px`
    );
    document.documentElement.style.setProperty(
      "--window-width",
      `${document.documentElement.clientWidth}`
    );
    const heroBody = document.querySelector(".hero-body");
    const cookie = readCookie("newsletter-cookie");
    if (!cookie) {
      openModal();
    }

    if (newsletterBtn) {
      newsletterBtn.onclick = function () {
        openModal();
      };
    }
    if (openModalBtn) {
      openModalBtn.onclick = function () {
        openModal();
      };
    }
    if (sendBtn) {
      sendBtn.onclick = function () {
        const cookie = readCookie("newsletter-cookie");
        if (!cookie) {
          createCookie("newsletter-cookie", "geese-cookie", 30);
        }
      };
    }

    closeModalBtn.onclick = function () {
      closeModal();
    };
    window.onclick = function (event) {
      if (event.target == sibForm) {
        closeModal();
      }
    };
    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    });
    window.addEventListener("resize", () => {
      document.documentElement.style.setProperty(
        "--window-width",
        `${document.documentElement.clientWidth}`
      );
      body.style.width = `${document.documentElement.clientWidth}px`;
    });
    function closeModal() {
      const scrollY = body.style.top;
      if (heroBody) {
        heroBody.style.opacity = "1";
      }
      body.classList.remove("modal-is-open");
      modalNewsletter.style.display = "none";
      body.style.top = "";
      body.style.width = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      const cookie = readCookie("newsletter-cookie");
      if (!cookie) {
        createCookie("newsletter-cookie", "geese-cookie", 30);
      }
    }
    function openModal() {
      const scrollY =
        document.documentElement.style.getPropertyValue("--scroll-y");
      const width =
        document.documentElement.style.getPropertyValue("--window-width");
      if (heroBody) {
        heroBody.style.opacity = "0";
      }
      modalNewsletter.style.display = "block";
      body.classList.add("modal-is-open");
      body.style.top = `-${scrollY}`;
      body.style.width = `${width}px`;
    }
    function createCookie(name, value, days) {
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = "; expires=" + date.toGMTString();
      } else var expires = "";
      document.cookie = name + "=" + value + expires + "; path=/";
    }
    function readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  }
}

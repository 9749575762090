import {StartPageLoadmore} from "../Components/StartPageLoadmore";

export default {
    init() {
        StartPageLoadmore()
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};

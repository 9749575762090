import {VideosLoadmore} from "../Components/VideosLoadmore";
import {VideosFilter} from "../Components/VideosFilter";

export default {
    init() {
        VideosLoadmore()
        VideosFilter()
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};

import $ from 'jquery';

import './style.scss';
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import pageTemplatePageThemenvideos from './routes/pageTemplatePageThemenvideos';
import category from './routes/category';


//import beispiel-seite from './routes/beispiel-seite';


/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  pageTemplatePageThemenvideos,
  category
  // beachte die geänderte Schreibweise:
  // beispielSeite
});

/** Load Events */
window.onload = function () {
  routes.loadEvents();
};
// $(document).ready(() => routes.loadEvents());

import bulmaCollapsible from '@creativebulma/bulma-collapsible';

export function accordion() {
    const presseCollapsibleItems = bulmaCollapsible.attach('.is-collapsible');
    const accordionButtons = [...document.getElementsByClassName('accordion-presse')];
    const accordionDates = [...document.getElementsByClassName('accordion-date')];
    const arrowImages = [...document.getElementsByClassName('arrow-open-presse')];
    const pageNumbers = [...document.getElementsByClassName('page-numbers')];

    if (accordionButtons !== 'undefined' && accordionButtons != null) {
        accordionButtons.map(button => {
            button.addEventListener('click', () => {
                button.firstElementChild.classList.toggle('rotate-arrow')
            })
        });
        accordionDates.map(button => {
            button.addEventListener('click', () => {
                console.log('working', button.nextElementSibling)
                button.parentElement.nextElementSibling.firstElementChild.classList.toggle('rotate-arrow')
            })
        });
    }

    if (pageNumbers !== 'undefined' && pageNumbers != null) {
        pageNumbers.map(pageNumber => {
            if (pageNumber.classList.contains('current')) {
                pageNumber.parentElement.style.backgroundColor = 'green';
                pageNumber.style.color = '#fff';
            } else {
                pageNumber.parentElement.style.backgroundColor = '#fff';
                pageNumber.style.color = '#green';
            }
        })
    }
}
import Swiper from 'swiper/bundle';

export function slider() {
    var sliderHero = new Swiper('.slider-hero', {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next-header'
        },
        breakpoints: {
            1366: {
                slidesPerView: 2,
                spaceBetween: 0
            },
            1820: {
                slidesPerView: 3,
                spaceBetween: 0
            }
        }
    });


    // VIDEO-PICTURES GALLERY:

    var galleryThumbs = new Swiper('.gallery-thumbs', {
        slideToClickedSlide: true,
        centeredSlides: true,
        spaceBetween: 10,
        slidesPerView: 3,
        loop: true,
        observer: true,
        observeParents: true
    });

    var galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 0,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        thumbs: {
            swiper: galleryThumbs,
        },
        observer: true,
        observeParents: true
    });

    var galleryThumbsThemenvideos = new Swiper('.gallery-thumbs-themenvideos', {
        slideToClickedSlide: true,
        direction: 'vertical',
        spaceBetween: 10,
        slidesPerView: 2,
        loop: true,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        draggable: true
    });

    var galleryTopThemenvideos = new Swiper('.gallery-top-themenvideos', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next-themenvideos',
            prevEl: '.swiper-button-prev-themenvideos',
        },
        thumbs: {
            swiper: galleryThumbsThemenvideos,
        },
        observer: true,
        observeParents: true,
        draggable: true
    });

    window.addEventListener("load", () => {
        galleryTop.update();
        galleryThumbs.update();
        galleryTopThemenvideos.update();
        galleryThumbsThemenvideos.update();
    });

    var nexts = document.querySelectorAll('.swiper-button-next')
    var prevs = document.querySelectorAll('.swiper-button-prev')

    var thumbs = document.querySelectorAll('.swiper-container-thumbs')

    function resetThumbs() {
        thumbs.forEach(item => {
            listenOnThumb(item)
        })
    }

    function listenOnThumb(item) {

        var prevThumb = item.querySelector('.swiper-slide-prev')
        var nextThumb = item.querySelector('.swiper-slide-next')

        prevThumb.addEventListener('click', e => {
            stopOnPrev()
        })

        nextThumb.addEventListener('click', e => {
            stopOnNext();
        })
    }

    nexts.forEach(next => {
        next.addEventListener('click', e => {
            stopOnNext();
        })
    })

    prevs.forEach(prev => {
        prev.addEventListener('click', e => {
            stopOnPrev();
        })
    })

    function stopOnNext() {
        var prevOlds = document.querySelectorAll('.swiper-slide-prev')
        var duplicatePrevOlds = document.querySelectorAll('.swiper-slide-duplicate-prev')
        prevOlds.forEach(item => {
            onClick(item)
        })
        if (duplicatePrevOlds) {
            duplicatePrevOlds.forEach(item => {
                onClick(item)
            })
        }
        resetThumbs()
    }

    function stopOnPrev() {
        var nextOlds = document.querySelectorAll('.swiper-slide-next')
        var duplicatePrevOlds = document.querySelectorAll('.swiper-slide-duplicate-next')

        nextOlds.forEach(item => {
            onClick(item)
        })

        if (duplicatePrevOlds) {
            duplicatePrevOlds.forEach(item => {
                onClick(item)
            })
        }
        resetThumbs()
    }

    function onClick(old) {
        var thumb = old.querySelector('.thumbnail')
        if (thumb !== null && thumb !== undefined) {
            thumb.style.display = 'flex'
            var button = thumb.querySelector('.play-btn')
            if (button !== null && button !== undefined) {
                button.style.display = 'flex'
            }
            var iframe = old.querySelector('iframe')
            if (iframe) {
                iframe.src = ''
            }
        }
    }
}

export function StartPageLoadmore() {
    var button = document.getElementById('loadmore_news');
    var loading = document.getElementById('loading_animation');

    if (steven_loadmore_params.max_page <= steven_loadmore_params.current_page) {
        button.style.display = 'none'
    }
    document.getElementById('loadmore_news').addEventListener('click', function () {
        var beforeSend = function () {
            loading.classList.toggle('d-none')
            button.style.display = 'none'
        }
        beforeSend()
        var xhr = new XMLHttpRequest();
        xhr.open('POST', steven_loadmore_params.ajaxurl);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function () {
            if (xhr.status === 200) {
                if (xhr.responseText) {
                    loading.classList.toggle('d-none')
                    button.style.display = 'inline-flex'
                    var container = document.getElementById('response')
                    container.innerHTML = container.innerHTML + xhr.response;
                    steven_loadmore_params.current_page++;
                    if (steven_loadmore_params.current_page >= steven_loadmore_params.max_page)
                        button.style.display = 'none'
                } else {
                    console.log('no post')
                    button.style.display = 'none'
                }
            }
        };
        xhr.send("action=loadmore&query=" + encodeURI(steven_loadmore_params.posts) + "&page=" + steven_loadmore_params.current_page)
        return false;
    })
}

export function themen() {
    if (document.querySelectorAll('.themenuebersicht') !== 'undefined' && document.querySelectorAll('.themenuebersicht') != null) {

        var themenTitleList = [...document.querySelectorAll('.themen-title')];
        var dropdownMenus = [...document.querySelectorAll('.dropdown-menu-touch')];
        let toggleMenuDigitales = false;
        let toggleMenuFeminismus = false;
        let toggleMenuWeitereThemen = false;
        let isSet = false;

        if (window.innerWidth <= 768 && isSet == false && themenTitleList !== undefined) {
            isSet = true;
            addListener()
        }

        window.addEventListener(
            "resize",
            function () {
                if (window.innerWidth <= 768) {
                    isSet = true;
                    addListener()
                } else if (window.innerWidth > 768 && isSet == true) {
                    dropdownMenus.map(dropdownMenu => dropdownMenu.style.height = '');
                    dropdownMenus.map(dropdownMenu => dropdownMenu.style.display = '');
                    isSet = false;
                }
            },
            false
        );

        function addListener() {
            themenTitleList.map(themenTitle => {
                themenTitle.addEventListener('click', () => {
                    themenTitle.lastElementChild.classList.toggle('rotate-arrow');
                    if (themenTitle.classList.contains('link-digitales')) {
                        if (toggleMenuDigitales) {
                            themenTitle.nextElementSibling.style.height = '0';
                            themenTitle.nextElementSibling.style.display = 'none';
                        } else {
                            themenTitle.nextElementSibling.style.height = 'auto';
                            themenTitle.nextElementSibling.style.display = 'flex';
                        }
                        toggleMenuDigitales = !toggleMenuDigitales;
                    } else if (themenTitle.classList.contains('link-feminismus')) {
                        if (toggleMenuFeminismus) {
                            themenTitle.nextElementSibling.style.height = '0';
                            themenTitle.nextElementSibling.style.display = 'none';
                        } else {
                            themenTitle.nextElementSibling.style.height = 'auto';
                            themenTitle.nextElementSibling.style.display = 'flex';
                        }
                        toggleMenuFeminismus = !toggleMenuFeminismus;
                    } else {
                        if (toggleMenuWeitereThemen) {
                            themenTitle.nextElementSibling.style.height = '0';
                            themenTitle.nextElementSibling.style.display = 'none';
                        } else {
                            themenTitle.nextElementSibling.style.height = 'auto';
                            themenTitle.nextElementSibling.style.display = 'flex';
                        }
                        toggleMenuWeitereThemen = !toggleMenuWeitereThemen;
                    }
                });
            });

        };
    }
}
export function calendar() {
  var gridSkinMec = document.getElementById("mec_skin_323");
  if (gridSkinMec) {
    let i;
    var month = document.getElementById("mec_sf_month_323");
    var year = document.getElementById("mec_sf_year_323");

    var d = new Date();
    var y = d.getFullYear();
    var m = d.getMonth() + 1;
    var options = month.options;
    for (i = 0; i < options.length; i++) {
      if (parseInt(options[i].value) === m) {
        month.selectedIndex = i;
        break;
      }
    }
    var options_year = year.options;
    for (let j = 0; j < options_year.length; j++) {
      if (parseInt(options_year[j].value) === y) {
        year.selectedIndex = j;
        break;
      }
    }
  }
}

// window.jQuery = window.$ = require("jquery");
import Velocity from "velocity-animate";
import { navigation } from '../Components/Navigation';
import { slider } from '../Components/Slider';
import { accordion } from "../Components/AccordionPresse";
import { playvideo } from "../Components/PlayVideo";
import { themen } from "../Components/ThemenEffects";
import { eventTitle } from "../Components/EventTitle";
import {newsletter} from "../Components/newsletter";
import { calendar } from "../Components/calendar";
import { modalFormular } from "../Components/ModalFormular";
import { presseLoadmore } from "../Components/presseLoadmore";

export default {
    init() {
        navigation();
        slider();
        accordion();
        playvideo();
        themen();
        calendar();
        eventTitle();
        newsletter();
        modalFormular();
        presseLoadmore();
    },
    finalize() {

    },
}






export const presseLoadmore = () => {
    // console.log(gp_params.max_page, gp_params.current_page);
    var button = document.getElementById('presse-loadmore');
    var loading = document.getElementById('loading_animation');
    if (gp_params.max_page <= gp_params.current_page) {
        button.style.display = 'none'
    }
    if (button) {
        button.addEventListener('click', function () {
            var beforeSend = function () {
                loading.classList.toggle('is-hidden')
                button.style.display = 'none'
            }
            beforeSend()
            var xhr = new XMLHttpRequest();
            xhr.open('POST', gp_params.ajaxurl);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onload = function () {
                if (xhr.status === 200) {
                    if (xhr.responseText) {
                        loading.classList.toggle('is-hidden')
                        button.style.display = 'inline-flex'
                        var container = document.getElementById('ajax-container')
                        container.innerHTML = container.innerHTML + xhr.response;
                        gp_params.current_page++;
                        if (gp_params.current_page >= gp_params.max_page)
                            button.style.display = 'none'
                    } else {
                        console.log('no post')
                        button.style.display = 'none'
                    }
                }
            };
            xhr.send("action=presseschau&query=" + encodeURI(gp_params.posts) + "&page=" + gp_params.current_page)
            return false;
        })
    }
};

export function navigation() {
    var burger = document.querySelector('.hamburger');
    var nav = document.querySelector('.main-menu-wrapper');
    var nav2 = document.querySelector('.main-menu');
    var nav3 = document.querySelector('.navbar');
    var menuActive = false;
    let evAdded = false;

    burger.addEventListener('click', function () {
        if (window.innerWidth <= 768 && menuActive === true) {
            let slides = document.querySelectorAll(".is-sliding-menu");
            for (var i = 0; i < slides.length; i++) {
                slides[i].classList.remove('is-active');
            }
            removeEvents();
            burger.classList.remove('is-active');
            nav.classList.remove('is-active');
            nav2.classList.remove('is-active');
            nav3.classList.remove('is-active');
            evAdded = false
            menuActive = false
            return
        }

        burger.classList.toggle('is-active');
        nav.classList.toggle('is-active');
        nav2.classList.toggle('is-active');
        nav3.classList.toggle('is-active');
        if (window.innerWidth <= 768) {
            addEvents();
            evAdded = true;
            menuActive = true;
        }

    });

    window.addEventListener("resize", function () {
        if (window.innerWidth <= 768 && evAdded == false) {
            addEvents();
            evAdded = true
        } else if (window.innerWidth > 768) {
            removeEvents();
            evAdded = false
        }
    }, false)

    function addEvents() {
        var buttons = document.querySelectorAll(".arrow");
        for (var i = 0; i < buttons.length; i++) {
            let current = buttons[i];
            let next = buttons[i].nextSibling;
            let back = next.firstElementChild;
            current.addEventListener('click', function () {
                next.classList.add('is-active');
            });
            back.addEventListener('click', function () {
                next.classList.remove('is-active');
            });
        }
    }

    nav.addEventListener('click', closeNavBar);

    function closeNavBar(e) {
        if (e.target === this && window.innerWidth > 768) {
            nav.classList.remove('is-active');
            nav2.classList.remove('is-active');
            nav3.classList.remove('is-active');
            burger.classList.remove('is-active');
        } else {
            return
        }
    }

    function removeEvents() {
        var buttons = document.querySelectorAll(".arrow");
        for (var i = 0; i < buttons.length; i++) {
            let current = buttons[i];
            let next = buttons[i].nextSibling;
            let back = next.firstElementChild;
            next.classList.remove('is-active');
            current.style.pointerEvents = 'none';
            current.style.pointerEvents = 'auto';
            back.style.pointerEvents = 'none';
            back.style.pointerEvents = 'auto';
            nav3.classList.remove('navbar-mobile');
        }
    }
}
